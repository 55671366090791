@import "src/variables";

.icon {
  user-select: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: $black;
}

.icon--mediumlarge {
  font-size: 24px;
  padding: 4px;
  width: 40px;
  height: 40px;
}

.icon--medium {
  font-size: 18px;
  height: 36px;
}

.icon--verySmall {
  font-size: 12px;
  align-self: center;
  height: 16px;
  width: 16px;
}

.icon--small {
  font-size: 14px;
  align-self: center;
  height: 32px;
  width: 32px;
}

.icon--circle {
  border-radius: 50%;
  border: solid 1px $black;
  padding: 8px;
}

.icon--white {
  color: white;
  border-color: white;
}

.icon--blue {
  background-color: $blue;
  color: white;
  border-color: $blue;
}

.icon--orange {
  background-color: $orange;
  color: white;
  border-color: $orange;
}

.icon--yellow {
  background-color: $yellow;
  color: black;
  border-color: $yellow;
}

.icon--blueIcon {
  color: $blue;
}

.icon--red {
  color: $red;
  border-color: $red;
}

.icon--grey, .icon--darkGrey {
  color: $darkGrey;
  border-color: $darkGrey;
}

.icon--greyFill {
  background-color: $darkGrey;
  color: $white;
  border-color: $darkGrey;
}

.icon--green {
  color: $green;
  border-color: $green;
}

.icon--greenFill {
  background-color: $green;
  color: $white;
  border-color: $green;
}

.icon--greenSolid {
  background-color: $green;
  color: white;
  border-color: $green;
}

.icon--blueOutline {
  background-color: $white;
  color: $blue;
  border-color: $white;
}

.icon--large {
  font-size: 32px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon--xlarge {
  font-size: 80px;
}
