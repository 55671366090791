@import "src/variables";

.new-teamMovements {
  width: 100%;
  justify-content: space-between;
  min-height: 65vh;
}

.teamMovements__logout--desktop {
  margin-top: -16px;
  display: none !important;
  @include tablet {
    display: flex !important;
  }
}

.teamMovements__logout--mobile {
  margin-top: -16px;
  display: flex !important;
  align-self: flex-start;
  @include desktopSmall {
    display: none !important;
  }
}

.teamMovementsContent {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 16px 0;
  overflow: hidden;
  @include tablet {
    padding: 16px 0 32px 0;
  }
  @include desktopSmall {
    max-width: 75%;
    width: 75%;
  }
}

.teamMovements__add--mobile {
  border-bottom: 1px solid $mildGrey;
  margin-bottom: 24px;
  width: 100%;
  @include desktopSmall {
    display: none !important;
  }
}

.teamMovementsSidebar {
  display: none !important;
  padding: 16px 0;
  @include desktopSmall {
    display: flex !important;
    padding: 32px 0 32px 16px;
    width: 25%;
    max-width: 25%;
  }
  @include desktop {
    padding: 32px 16px 32px 16px;
  }
}

.teamMovements__content {
  width: 100%;
  max-width: 100%;
  min-height: 620px;
}

.addTeamMember {
  flex-direction: column-reverse;
  padding-bottom: 16px;
  align-items: center;
  button {
    height: 50px;
    @include tablet {
      height: 32px;
    }
  }
  img {
    margin: 24px auto 8px auto;
    width: 120px;
    max-width: 80%;
    @include tablet {
      margin: 50px auto 8px auto;
    }
  }
  @include tablet {
    flex-direction: column;
  }
}

.teamMovements__deskAvailabilityRow--desktop {
  display: none;
  @include smallTablet {
    display: flex;
  }
}

.teamMovements__sideBarOptions {

  & > * {
    border-bottom: 1px solid $mildGrey;
    padding: 12px 0;
    &:last-child {
      border-bottom: none;
    }
  }
}
