
.calendar {
  flex-direction: column;
  min-width: 224px;
  max-width: 266px;
  @include desktop {
    display: inline-flex;
  }
}

$maxWidth: 400px;

.calendar--fullWidth {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  .calendar__day {
    width: calc(100vw / 7);
    height: calc(100vw / 7);
    max-width: 48px;
    max-height: 48px;
  }
  .calendar__rowsContainer {
    min-height: 344px;
  }
  .body__xxsmall {
    font-size: 18px;
    line-height: 30px;
  }
  .calendar__week--active {
    border-radius: 32px;
  }
}

.calendar__week {
  display: flex;
  margin: 1px 0;
  user-select: none;
}

.calendar__day {
  border-radius: 50%;

  width: 38px;
  height: 38px;
  max-width: 38px;
  max-height: 38px;
  min-width: 38px;
  min-height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .15s;
  position: relative;
  overflow: hidden;
  p {
    z-index: 10;
  }
  &:hover {
    background-color: $blue10;
  }
  @media (min-width: #{$tablet}) {
    width: 34px;
    height: 34px;
    max-width: 34px;
    max-height: 34px;
    min-width: 34px;
    min-height: 34px;
  }
  @media (min-width: #{$desktopMid}) {
    width: 38px;
    height: 38px;
    max-width: 38px;
    max-height: 38px;
    min-width: 38px;
    min-height: 38px;
  }
}

.calendar__day--header {
  @extend .calendar__day;
  font-weight: bold;
  cursor: pointer;
  color: $darkGrey;
  &:hover {
    background-color: inherit;
  }
}

.calendar__day--disabled, .calendar__day--disabled p {
  color: $mildGrey !important;
}

.calendar--showHighlightedRange .calendar__day--active::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: $blue10;
  z-index: -1;
}

.calendar--showHighlightedRange .calendar__day--end::before {
  content: '';
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: $blue10;
  z-index: -1;
}

.calendar__day--active {
  background-color: $blue;
  p {
    color: white !important;
  }
  &:hover {
    background-color: $blue;
  }
}

.calendar__day--highlight--blue {
  background-color: $blue10;
  &:hover {
    background-color: $blue50;
  }
}
.calendar__day--highlight--yellow {
  background-color: $orange10;
  &:hover {
    background-color: $orange50;
  }
}
.calendar__day--highlight--green {
  background-color: $green;
  p {
    color: white !important;
  }
  &:hover {
    background-color: $darkGreen;
  }
}
.calendar__day--highlight--lightGreen {
  background-color: rgba(32, 204, 111, 0.3);
  p {
    color: $black !important;
  }
  &:hover {
    background-color: rgba(32, 204, 111, 0.8);
  }
}
.calendar__day--highlight--grey {
  background-color: rgba(0, 0, 0, 0.05);
  p {
    color: $black !important;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.calendar__day--highlight--lilac {
  background-color: rgba(128, 0, 128, 0.1);
  p {
    color: $black !important;
  }
  &:hover {
    background-color: rgba(128, 0, 128, 0.4);
  }
}

.calendar__day--background--am {
  z-index: 1;
  width: 100%;
  height: 50%;
  top: 50%;
  left: 0;
  position: absolute;
  background-color: white;
}

.calendar__day--background--pm {
  z-index: 1;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
}


.calendar__day--hidden {
  cursor: pointer;
  background-color: transparent !important;
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}

.calendar__week--active {
  background-color: $blue10;
  border-radius: 22px;
}

.calendar__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.calendar__controls {
  user-select: none;
  display: flex;
}

.calendar__control {
  margin-left: 6px;
  max-height: 32px;
  max-width: 32px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:first-child {
    margin-left: 0;
  }
}

.calendar__day--end {
  background-color: $green;
  p {
    color: white !important;
  }
  &:hover {
    background-color: $green;
  }
}
